/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  MOLNoData,
  ORGDataTable,
  IORGDataTableColumn,
  ATMCheckbox,
  ATMButton,
  IORGDataTableQueryState,
  ATMTable,
  ATMIcon,
  ATMDatePicker,
  ATMForm,
  ATMField,
  ATMInput,
  formatTime,
} from 'shared-it-appmod-ui';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import {
  DailyReportAction,
  LERNotificationStatus,
  LerRequestStatusOMS,
  OutageType,
  TransmissionOutageStatus,
} from 'src/constants';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Moment, { format24hTime } from 'src/libraries/moment.library';
import { useDailyReportsContext } from 'src/contexts/daily-reports.context';
import {
  dailyReportsActionTypes,
  IDailyReportsState,
} from 'src/ducks/daily-reports.duck';
import {
  DailyReportUpdateSchema,
  IDailyReport,
  IDailyReportUpdate,
  IDailyReportVoltageGroup,
  IETS,
  IRadialized,
} from 'src/models/daily-reports.model';
import { getDailyReportsStatus } from 'src/selectors/daily-reports.selector';
import { sortBy } from 'lodash';
import history from 'src/history';
import { Link } from 'react-router-dom';
import { ISystemChange } from 'src/models/system-change.model';
import styles from '../daily-reports.module.scss';

export const dailyReportOverviewColumns: IORGDataTableColumn<IDailyReportVoltageGroup>[] =
  [
    {
      index: 'select',
      title: '',
      width: '3%',
    },
    {
      index: 'chevron',
      title: '',
      width: '3%',
    },
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      width: '5%',
    },
    {
      index: 'startTmTxt',
      title: Lang.LBL_START_TIME,
      width: '10%',
    },
    {
      index: 'voltNm',
      title: Lang.LBL_KV,
      width: '5%',
    },
    {
      index: 'outgFacNm',
      title: Lang.LBL_FACILITIES,
      width: '25%',
    },
    {
      index: 'outageTypeDesc',
      title: Lang.LBL_OUTAGE_TYPE,
      width: '10%',
    },
    {
      index: 'crewName',
      title: Lang.LBL_CREW_INFO_NAME,
      width: '10%',
    },
    {
      index: 'stopTmTxt',
      title: Lang.LBL_END_TIME,
      width: '10%',
    },
    {
      index: 'etr',
      title: Lang.LBL_ETR,
      width: '5%',
    },
    {
      index: 'recalTm',
      title: Lang.LBL_RECALL_TIME,
      width: '7%',
    },
    {
      index: 'outageStatus',
      title: `${Lang.LBL_OC_LER_ISO} ${Lang.LBL_STATUS}`,
      width: '7%',
    },
  ];

type IProps = {
  reportDate: Date;
  reportData?: IDailyReportVoltageGroup[];
  dailyResportesLERs?: IDailyReportsState[];
  handleFetch?: (params: Partial<IORGDataTableQueryState>) => void;
  setOverviewEmail: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  setDailyReportsLERs: React.Dispatch<React.SetStateAction<IDailyReport[]>>;
  setDailyReports: React.Dispatch<
    React.SetStateAction<
      | IDailyReportVoltageGroup[]
      | IRadialized[]
      | IETS[]
      | ISystemChange[]
      | undefined
    >
  >;
  setSelectAllFlag: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  selectAllFlag: boolean | undefined;
  setExcludeFlag: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  excludeFlag: boolean | undefined;
  setStartingEndingFlag: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  startingEndingFlag: boolean | undefined;
  setCheckType: React.Dispatch<React.SetStateAction<string | undefined>>;
  checkType: string | undefined;
};

const DailyReportOverviewTable: React.FC<IProps> = ({
  reportDate,
  reportData,
  setDailyReportsLERs,
  setDailyReports,
  setOverviewEmail,
  setSelectAllFlag,
  selectAllFlag,
  setExcludeFlag,
  excludeFlag,
  setStartingEndingFlag,
  startingEndingFlag,
  setCheckType,
  checkType,
}) => {
  const { state, actions } = useDailyReportsContext();
  const { getUrl } = useLocationParams(['lerView']);
  const [displayRow, setDisplayRow] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean | undefined>(
    selectAllFlag
  );
  const [exclude, setExclude] = useState<boolean | undefined>(excludeFlag);
  const [startingEnding, setStartingEnding] = useState<boolean | undefined>(
    startingEndingFlag
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [selectedIdList, setSelectedIdList] = useState<string[] | undefined>(
    []
  );
  const [allFilteredData, setAllFilteredData] = useState<
    IDailyReportVoltageGroup[]
  >([]);
  const [outTime, setOutTime] = useState<string>('');
  const [inTime, setInTime] = useState<string>('');
  const [ready, setReady] = useState<boolean | undefined>(false);
  const [loader, setLoader] = useState<Array<string>>([]);

  const { fetching } = getDailyReportsStatus(
    state,
    dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST
  );

  const handleSelectedElements = useCallback(
    (type: string, checked: boolean | undefined, valueId?: number) => {
      setReady(checked);
      if (type === 'all') {
        let filteredData: IDailyReportVoltageGroup[] = reportData ?? [];
        if (reportData !== undefined) {
          filteredData = reportData;
        }
        setImmediate(() => {
          setAllFilteredData(filteredData);
          setSelectAllFlag(checked);
          setExcludeFlag(false);
          setStartingEndingFlag(false);
          setCheckType(type);
        });
      }
      if (type === 'exclude') {
        let excludeData: IDailyReportVoltageGroup[] | undefined;
        if (checked === false) {
          excludeData = reportData;
        }
        if (checked === true) {
          if (reportData !== undefined) {
            excludeData =
              reportData.map((item) => {
                const filtered = { ...item };
                filtered.dailyReports = item.dailyReports.filter(
                  ({ outageTypeDesc }) =>
                    outageTypeDesc !== OutageType.External &&
                    outageTypeDesc !== OutageType.Info
                );
                return filtered;
              }) ?? [];
          }
        }
        setImmediate(() => {
          setAllFilteredData(excludeData ?? []);
          setExcludeFlag(checked);
          setSelectAll(false);
          setStartingEndingFlag(false);
          setCheckType(type);
        });
      }
      if (type === 'startingEnding') {
        let filteredData: IDailyReportVoltageGroup[] = reportData ?? [];
        filteredData =
          reportData?.map((item) => {
            const filtered = { ...item };
            filtered.dailyReports = item.dailyReports.filter(
              (v) =>
                v.startTmTxt !== 'In Progress' || v.stopTmTxt !== 'In Progress'
            );
            return filtered;
          }) ?? [];

        if (checked === false) {
          filteredData = reportData ?? [];
        }
        setImmediate(() => {
          setAllFilteredData(filteredData);
          setStartingEndingFlag(checked);
          setSelectAll(false);
          setExcludeFlag(false);
          setCheckType(type);
        });
      }
      if (type === 'individual') {
        setReady(undefined);
        if (checked === false) {
          setSelectedIdList((value) =>
            value ? value.filter((v) => v !== valueId?.toString()) : []
          );
        }
        if (checked === true) {
          if (selectedIdList && valueId) {
            setSelectedIdList([...selectedIdList, valueId?.toString()]);
          } else if (valueId) {
            setSelectedIdList([valueId?.toString()]);
          }
        }
      }
    },
    [
      setAllFilteredData,
      allFilteredData,
      setSelectAll,
      setSelectedIdList,
      selectAll,
      exclude,
      startingEnding,
      reportData,
      selectedIdList,
    ]
  );
  useEffect(() => {
    if (ready) {
      const requestIds: string[] = [];
      allFilteredData.forEach((item) => {
        item?.dailyReports.forEach(({ requestId }) => {
          requestIds.push(String(requestId));
        });
      });
      setSelectedIdList(requestIds);
    } else if (ready === false) {
      setSelectedIdList([]);
    }
  }, [setSelectedIdList, allFilteredData, ready]);

  useEffect(() => {
    setAllFilteredData(reportData ?? []);
    setDisplayRow([]);
    if (checkType === 'startingEnding') {
      handleSelectedElements(checkType, startingEnding);
    } else if (checkType === 'all') {
      handleSelectedElements(checkType, selectAll);
    } else if (checkType === 'exclude') {
      handleSelectedElements(checkType, exclude);
    }
  }, [reportData, setDisplayRow]);

  const handleViewSelected = useCallback(() => {
    const lerDetails = allFilteredData
      .map((v) => v.dailyReports)
      .flat(1)
      .filter((v) => selectedIdList?.includes(v.requestId?.toString()));
    setDailyReportsLERs(lerDetails ?? []);
  }, [setDailyReportsLERs, allFilteredData, selectedIdList]);

  const handleSubmitUpdate = useCallback(
    async (formData) => {
      const newFormData: IDailyReportUpdate = formData;
      setLoader((value) => [...value, formData.requestId.toString()]);
      setIsUpdate(true);
      if (
        formData.action === DailyReportAction.UPDATE ||
        formData.action === DailyReportAction.SUBMIT_OUT_TIME
      ) {
        newFormData.outTm = formData.outTime
          ? Moment(
              `${Moment(formData.outTm).format('MM/DD/YYYY')} ${
                formData.outTime
              }`,
              'MM/DD/YYYY HH:mm'
            ).toDate()
          : undefined;
      }
      if (
        formData.action === DailyReportAction.UPDATE ||
        formData.action === DailyReportAction.SUBMIT_IN_TIME
      ) {
        newFormData.inTm = formData.inTime
          ? Moment(
              `${Moment(formData.inTm).format('MM/DD/YYYY')} ${
                formData.inTime
              }`,
              'MM/DD/YYYY HH:mm'
            ).toDate()
          : undefined;
      }
      const response = await actions.updatePUT(formData.requestId, newFormData);
      if (!response.error) {
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_DAILY_REPORT_UPDATE_SUCCESS,
            formData.requestId
          ).toString(),
          { delay: 15000 }
        );
      } else {
        ToastError(
          <span>
            <p>
              {LERNotificationStatus.REJECTED}
              {' - #'}
              {formData.requestId}
            </p>
            {response.error.map((v, i) => (
              <p key={i}>{v.description}</p>
            ))}
          </span>,
          { delay: 25000 }
        );
      }
      setLoader((value) =>
        value.filter((v) => v !== formData.requestId.toString())
      );
    },
    [actions, inTime, outTime, reportDate, setLoader, setIsUpdate]
  );

  const handleDisplayRow = useCallback(
    (rowName) => {
      setOutTime('');
      setInTime('');
      if (displayRow.includes(rowName)) {
        setDisplayRow((value) => value.filter((v) => v !== rowName));
      } else {
        setDisplayRow((value) => [...value, rowName]);
      }
    },
    [setDisplayRow, setOutTime, setInTime, displayRow]
  );

  const handleOutTime = useCallback(
    (oTime) => {
      setOutTime(oTime);
    },
    [setOutTime]
  );

  const handleInTime = useCallback(
    (iTime) => {
      setInTime(iTime);
    },
    [setInTime]
  );

  useEffect(() => {
    const fetchData = async () => {
      const responseGET = await actions.listOverviewGET({
        filters: [
          {
            name: 'date',
            value: reportDate,
          },
        ],
      });

      setDailyReports(responseGET.payload?.data ?? []);
    };
    if (isUpdate) {
      if (loader.length === 0) {
        fetchData();
        setIsUpdate(false);
        setOutTime('');
        setInTime('');
        setDisplayRow([]);
      }
    }
  }, [
    loader,
    isUpdate,
    setIsUpdate,
    setOutTime,
    setInTime,
    setDisplayRow,
    actions,
  ]);

  const customRowComponent = (
    dataTable: IDailyReportVoltageGroup,
    key: number,
    withToolbars?: boolean
  ) => {
    return dataTable ? (
      <>
        <ATMTable.Row
          key={key}
          style={
            withToolbars
              ? {}
              : { lineHeight: 'normal', pageBreakBefore: 'avoid' }
          }
        >
          <ATMTable.Cell
            colSpan={dailyReportOverviewColumns.length}
            className={
              withToolbars
                ? styles.subHeadingStyle
                : styles.subHeadingPrintStyle
            }
          >
            {dataTable.voltNm?.includes('kV')
              ? dataTable.voltNm
              : `${dataTable.voltNm} KV`}
          </ATMTable.Cell>
        </ATMTable.Row>
        {dataTable.dailyReports?.map((dailyReport, index) => {
          const rowName = [
            dailyReport.requestId,
            dailyReport.voltNm,
            index,
          ].join('_');
          return (
            <ATMTable.Row key={index}>
              <ATMTable.Cell
                colSpan={dailyReportOverviewColumns.length}
                className={styles.groupCell}
              >
                <ATMTable>
                  <colgroup>
                    {dailyReportOverviewColumns.map((val, k) => (
                      <col width={val.width || undefined} key={k} />
                    ))}
                  </colgroup>
                  <ATMTable.Body>
                    <ATMTable.Row>
                      <ATMTable.Cell>
                        {withToolbars && (
                          <ATMCheckbox
                            onClick={(_, { checked }) => {
                              if (checked !== undefined) {
                                handleSelectedElements(
                                  'individual',
                                  checked,
                                  dailyReport.requestId
                                );
                              }
                            }}
                            style={{ marginBottom: '0px' }}
                            checked={selectedIdList?.some(
                              (value) => dailyReport.requestId === Number(value)
                            )}
                          />
                        )}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {withToolbars &&
                          (displayRow.includes(rowName) ? (
                            <ATMButton
                              basic
                              size="huge"
                              className={styles.btnChevron}
                              content={<ATMIcon name="angle down" />}
                              onClick={() => handleDisplayRow(rowName)}
                            />
                          ) : (
                            <ATMButton
                              basic
                              size="huge"
                              className={styles.btnChevron}
                              content={<ATMIcon name="angle right" />}
                              onClick={() => handleDisplayRow(rowName)}
                            />
                          ))}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        <Link
                          to={getUrl({ lerView: dailyReport.requestId })}
                          style={{ textDecoration: 'underline' }}
                        >
                          {`${
                            dailyReport.significantCostInd &&
                            dailyReport.significantCostInd === 'Y'
                              ? '$ '
                              : ''
                          }${dailyReport.requestId}`}
                        </Link>
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.actualSwitchOutTm ||
                        dailyReport.actualStrtTm ? (
                          <span>
                            {dailyReport.startTmTxt}
                            <br />
                            {dailyReport.actualSwitchOutTm
                              ? format24hTime(dailyReport.actualSwitchOutTm)
                              : format24hTime(dailyReport.actualStrtTm)}
                          </span>
                        ) : (
                          dailyReport.startTmTxt
                        )}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {!withToolbars ? (
                          <ATMCheckbox
                            style={{ marginBottom: '0px' }}
                            label={`${dailyReport.voltNm}${
                              dailyReport.testVoltageApplied
                                ? ` (${Lang.NTE_TEST_VOLTAGE})`
                                : ''
                            }`}
                          />
                        ) : (
                          `${dailyReport.voltNm}${
                            dailyReport.testVoltageApplied
                              ? ` (${Lang.NTE_TEST_VOLTAGE})`
                              : ''
                          }`
                        )}
                      </ATMTable.Cell>
                      <ATMTable.Cell
                        style={!withToolbars ? { fontWeight: 'bold' } : {}}
                      >
                        {dailyReport.outgFacNm}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.outageTypeDesc}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.crewName ?? '--'}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.actualSwitchInTm ||
                        dailyReport.actualStopTm ? (
                          <span>
                            {dailyReport.stopTmTxt}
                            <br />
                            {dailyReport.actualSwitchInTm
                              ? format24hTime(dailyReport.actualSwitchInTm)
                              : format24hTime(dailyReport.actualStopTm)}
                          </span>
                        ) : (
                          dailyReport.stopTmTxt
                        )}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {!withToolbars && (
                          <ATMCheckbox style={{ marginBottom: '0px' }} />
                        )}
                        {dailyReport.etr}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.recalTm
                          ? `${dailyReport.recalTm} Hr`
                          : '--'}
                      </ATMTable.Cell>
                      <ATMTable.Cell>
                        {dailyReport.outageStatus ? (
                          dailyReport.outageStatus ===
                            TransmissionOutageStatus.SUBMITTED ||
                          dailyReport.outageStatus ===
                            TransmissionOutageStatus.APPROVED ? (
                            <span>
                              {dailyReport.farFan === 'FAR' ? (
                                <strong>{dailyReport.farFan}</strong>
                              ) : (
                                dailyReport.farFan
                              )}
                              {` - ${dailyReport.omsId}`}
                            </span>
                          ) : (
                            <span>
                              {dailyReport.farFan === 'FAR' ? (
                                <strong>{dailyReport.farFan}</strong>
                              ) : (
                                dailyReport.farFan
                              )}
                              {` - ${dailyReport.omsId}`}
                              <p>{dailyReport.outageStatus}</p>
                            </span>
                          )
                        ) : (
                          '--'
                        )}
                      </ATMTable.Cell>
                    </ATMTable.Row>
                    {dailyReport.wrkDesc && (
                      <ATMTable.Row className={styles.noHover}>
                        <ATMTable.Cell className={styles.noBorders} />
                        <ATMTable.Cell className={styles.noBorders} />
                        <ATMTable.Cell
                          className={styles.longTextCellStyle}
                          colSpan={dailyReportOverviewColumns.length - 2}
                        >
                          <strong>{dailyReport.wrkDesc}</strong>
                        </ATMTable.Cell>
                      </ATMTable.Row>
                    )}
                    {dailyReport?.dailyNotes.map((note, i) => (
                      <ATMTable.Row key={i} className={styles.noHover}>
                        <ATMTable.Cell className={styles.noBorders} />
                        <ATMTable.Cell className={styles.noBorders} />
                        <ATMTable.Cell
                          className={styles.longTextCellStyle}
                          colSpan={dailyReportOverviewColumns.length - 2}
                        >
                          {note}
                        </ATMTable.Cell>
                      </ATMTable.Row>
                    ))}
                    {displayRow.includes(rowName) && (
                      <ATMTable.Row>
                        <ATMTable.Cell
                          colSpan={dailyReportOverviewColumns.length}
                        >
                          {sortBy(dailyReport.actions)
                            .reverse()
                            .map((action, ind) => {
                              return (
                                <>
                                  <ATMForm
                                    key={ind}
                                    mode="onChange"
                                    defaultValues={{
                                      ...dailyReport,
                                      inTime:
                                        format24hTime(
                                          dailyReport.actualSwitchInTm ??
                                            dailyReport.actualStopTm
                                        ) ?? undefined,
                                      outTime:
                                        format24hTime(
                                          dailyReport.actualSwitchOutTm ??
                                            dailyReport.actualStrtTm
                                        ) ?? undefined,
                                    }}
                                    onSubmit={handleSubmitUpdate}
                                    validationSchema={DailyReportUpdateSchema}
                                    style={{
                                      height: '3vh',
                                      display: 'inline',
                                      marginRight: '0.5em',
                                    }}
                                  >
                                    {({
                                      control,
                                      formState: { errors },
                                      getValues,
                                      resetField,
                                    }) => {
                                      return (
                                        <>
                                          {(action ===
                                            DailyReportAction.UPDATE ||
                                            action ===
                                              DailyReportAction.SUBMIT_OUT_TIME) && (
                                            <span
                                              className={styles.expandedRow}
                                            >
                                              <span>{Lang.LBL_OUT_TIME}</span>
                                              <ATMField
                                                name="outTm"
                                                as={ATMDatePicker}
                                                format="MM/DD/YYYY"
                                                control={control}
                                                error={errors.outTm}
                                                defaultValue={
                                                  dailyReport.actualSwitchOutTm ??
                                                  dailyReport.actualStrtTm ??
                                                  (dailyReport.startTmTxt !==
                                                  'In Progress'
                                                    ? new Date()
                                                    : null)
                                                }
                                                value={
                                                  dailyReport.actualSwitchOutTm ??
                                                  dailyReport.actualStrtTm ??
                                                  (dailyReport.startTmTxt !==
                                                  'In Progress'
                                                    ? new Date()
                                                    : null)
                                                }
                                                onChange={([_, { value }]) => {
                                                  if (!value) {
                                                    setImmediate(() => {
                                                      resetField('outTime', {
                                                        defaultValue: '',
                                                      });
                                                    });
                                                  }
                                                  return value || undefined;
                                                }}
                                              />
                                              <ATMField
                                                clearable
                                                as={ATMInput}
                                                name="outTime"
                                                control={control}
                                                error={errors.outTime}
                                                icon="clock"
                                                maxLength={5}
                                                placeholder="Time"
                                                onChange={([_, { value }]) => {
                                                  const formattedTime =
                                                    formatTime(value);
                                                  if (value.length === 5) {
                                                    const hour = Number(
                                                      value.split(':')[0]
                                                    );
                                                    const min = Number(
                                                      value.split(':')[1]
                                                    );
                                                    if (
                                                      !(hour > 23 || min > 59)
                                                    ) {
                                                      handleOutTime(value);
                                                    }
                                                  }
                                                  return formattedTime;
                                                }}
                                              />
                                            </span>
                                          )}
                                          {(action ===
                                            DailyReportAction.UPDATE ||
                                            action ===
                                              DailyReportAction.SUBMIT_IN_TIME) && (
                                            <span
                                              className={styles.expandedRow}
                                            >
                                              <span>{Lang.LBL_IN_TIME}</span>
                                              <ATMField
                                                name="inTm"
                                                as={ATMDatePicker}
                                                format="MM/DD/YYYY"
                                                control={control}
                                                defaultValue={
                                                  dailyReport.actualSwitchInTm ??
                                                  dailyReport.actualStopTm ??
                                                  (dailyReport.stopTmTxt !==
                                                  'In Progress'
                                                    ? new Date()
                                                    : null)
                                                }
                                                value={
                                                  dailyReport.actualSwitchInTm ??
                                                  dailyReport.actualStopTm ??
                                                  (dailyReport.stopTmTxt !==
                                                  'In Progress'
                                                    ? new Date()
                                                    : null)
                                                }
                                                onChange={([_, { value }]) => {
                                                  if (!value) {
                                                    setImmediate(() => {
                                                      resetField('inTime', {
                                                        defaultValue: '',
                                                      });
                                                    });
                                                  }
                                                  return value || undefined;
                                                }}
                                              />
                                              <ATMField
                                                clearable
                                                as={ATMInput}
                                                name="inTime"
                                                key={`${inTime}`}
                                                control={control}
                                                error={errors.inTime}
                                                icon="clock"
                                                maxLength={5}
                                                placeholder="Time"
                                                onChange={([_, { value }]) => {
                                                  const formattedTime =
                                                    formatTime(value);
                                                  if (value.length === 5) {
                                                    const hour = Number(
                                                      value.split(':')[0]
                                                    );
                                                    const min = Number(
                                                      value.split(':')[1]
                                                    );
                                                    if (
                                                      !(hour > 23 || min > 59)
                                                    ) {
                                                      handleInTime(value);
                                                    }
                                                  }
                                                  if (
                                                    formattedTime &&
                                                    formattedTime.length === 4
                                                  ) {
                                                    return `${formattedTime.slice(
                                                      0,
                                                      2
                                                    )}:${formattedTime.slice(
                                                      0,
                                                      4
                                                    )}`;
                                                  }
                                                  return formattedTime;
                                                }}
                                              />
                                            </span>
                                          )}
                                          <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="requestId"
                                            defaultValue={dailyReport.requestId}
                                            type="hidden"
                                            className="hidden"
                                            key={ind}
                                          />
                                          <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="omsId"
                                            defaultValue={dailyReport.omsId}
                                            type="hidden"
                                            className="hidden"
                                          />
                                          <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="action"
                                            defaultValue={action}
                                            type="hidden"
                                            className="hidden"
                                          />
                                          <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="startTm"
                                            defaultValue={dailyReport.startTm}
                                            type="hidden"
                                            className="hidden"
                                          />
                                          <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="stopTm"
                                            defaultValue={dailyReport.stopTm}
                                            type="hidden"
                                            className="hidden"
                                          />
                                          <div className={styles.expandedRow}>
                                            <ATMButton
                                              secondary
                                              key={ind}
                                              type="submit"
                                              loading={loader.includes(
                                                dailyReport.requestId.toString()
                                              )}
                                              content={action}
                                              disabled={
                                                loader.includes(
                                                  dailyReport.requestId.toString()
                                                ) ||
                                                (action ===
                                                  DailyReportAction.UPDATE &&
                                                dailyReport.stopTmTxt !==
                                                  'In Progress' &&
                                                dailyReport.startTmTxt !==
                                                  'In Progress' &&
                                                getValues().outTime
                                                  ? getValues().inTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : action ===
                                                      DailyReportAction.UPDATE &&
                                                    dailyReport.stopTmTxt !==
                                                      'In Progress' &&
                                                    dailyReport.startTmTxt !==
                                                      'In Progress'
                                                  ? getValues().outTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : action ===
                                                      DailyReportAction.UPDATE &&
                                                    dailyReport.stopTmTxt ===
                                                      'In Progress'
                                                  ? getValues().outTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : action ===
                                                      DailyReportAction.UPDATE &&
                                                    dailyReport.startTmTxt ===
                                                      'In Progress'
                                                  ? getValues().inTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : action ===
                                                    DailyReportAction.SUBMIT_IN_TIME
                                                  ? getValues().inTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : action ===
                                                    DailyReportAction.SUBMIT_OUT_TIME
                                                  ? getValues().outTime ===
                                                      undefined ||
                                                    dailyReport.outageStatus ===
                                                      LerRequestStatusOMS.PENDING
                                                  : dailyReport.outageStatus ===
                                                    LerRequestStatusOMS.PENDING)
                                              }
                                            />
                                          </div>
                                        </>
                                      );
                                    }}
                                  </ATMForm>
                                </>
                              );
                            })}
                        </ATMTable.Cell>
                      </ATMTable.Row>
                    )}
                  </ATMTable.Body>
                </ATMTable>
              </ATMTable.Cell>
            </ATMTable.Row>
          );
        })}
      </>
    ) : null;
  };

  const overviewPage = (withToolbars?: boolean) => (
    <ORGDataTable
      counter={withToolbars}
      celled={false}
      loading={fetching || !reportData}
      columns={dailyReportOverviewColumns}
      data={
        withToolbars
          ? allFilteredData ?? []
          : [
              ...allFilteredData.filter((v) => v.dsplyOrd !== null),
              ...allFilteredData.filter((v) => v.dsplyOrd === null),
            ]
      }
      customRowComponent={(dataTable, key) =>
        customRowComponent(dataTable, key, withToolbars)
      }
      location={history.location}
      handleLocation={history.push}
      noDataText={
        <MOLNoData
          icon="search"
          header={Lang.LBL_SEACH_VIEW_RECORDS}
          subHeader={Lang.NTE_NO_DATA_OVERVIEW}
        />
      }
    >
      {() => ({
        toolbars: withToolbars
          ? {
              selectAll: () => (
                <span className={styles.toolbars}>
                  <ATMCheckbox
                    label="Select All"
                    checked={selectAll}
                    onClick={(_, { checked }) => {
                      if (checked !== undefined) {
                        setImmediate(() => {
                          setSelectAll(checked);
                          setExclude(false);
                          setStartingEnding(false);
                          handleSelectedElements('all', checked);
                        });
                      }
                      return checked;
                    }}
                  />
                </span>
              ),
              exclude: () => (
                <span className={styles.toolbars}>
                  <ATMCheckbox
                    label="Exclude Info Only / External LERs"
                    checked={exclude}
                    onClick={(_, { checked }) => {
                      if (checked !== undefined) {
                        setSelectAll(false);
                        setExclude(checked);
                        setStartingEnding(false);
                        handleSelectedElements('exclude', checked);
                      }
                      return checked;
                    }}
                  />
                </span>
              ),
              outages: () => (
                <span className={styles.toolbars}>
                  <ATMCheckbox
                    label="Show Only outages starting or ending today"
                    checked={startingEnding}
                    onClick={(_, { checked }) => {
                      if (checked !== undefined) {
                        setSelectAll(false);
                        setExclude(false);
                        setStartingEnding(checked);
                        handleSelectedElements('startingEnding', checked);
                      }
                      return checked;
                    }}
                  />
                </span>
              ),
              buttons: () => (
                <ATMButton
                  secondary
                  size="tiny"
                  type="button"
                  content="View Selected"
                  onClick={handleViewSelected}
                  disabled={selectedIdList && selectedIdList.length === 0}
                />
              ),
            }
          : undefined,
      })}
    </ORGDataTable>
  );

  useEffect(() => {
    if (allFilteredData && allFilteredData.length > 0) {
      setOverviewEmail(<div id="overview-table">{overviewPage()}</div>);
    }
  }, [allFilteredData, setOverviewEmail]);

  return overviewPage(true);
};

export default DailyReportOverviewTable;
