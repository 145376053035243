/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-children-prop */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMLoader,
} from 'shared-it-appmod-ui';
import StarterKit from '@tiptap/starter-kit';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import TextEditor from 'src/components/organisms/editor/editor.component';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import Text from '@tiptap/extension-text';
import Focus from '@tiptap/extension-focus';
import { generateRealTimeLogBPORReport } from 'src/helpers/real-time-log.helper';
import TabHandler from 'src/components/organisms/editor/custom-extension/editor-tab.component';
import { Dimmer } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import Paragraph from '@tiptap/extension-paragraph';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import EditorMenuBar from './real-time-log-editor-menu-bar.component';
import styles from './real-time-log-bpor-report.module.scss';

type IProps = {
  reportDate: Date | undefined;
  version?: number | undefined;
  loading?: boolean;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  editorContent: string;

};

const RealTimeLogBPORReportView: React.FC<IProps> = ({ reportDate, loading, editorContent, setEditorContent }) => {
  const { state } = useRealTimeLogContext();
  const extensions = [StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }), TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Paragraph,
  Text,
  TextStyle,
  Color,
  Underline,
  HorizontalRule,
  TabHandler,
  Focus.configure({
    className: styles.hasFocus,
    mode: 'all',
  }),
  ];
  const editor = useEditor({
    extensions,
    autofocus: true,
    content: '',
    // editorProps: {
    //   attributes: {
    //     spellcheck: 'true',
    //   },
    // },
    onUpdate({ editor: editorCont }) {
      setEditorContent(editorCont.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      const reportTemplate = reportDate ? generateRealTimeLogBPORReport(state?.report ?? null, reportDate as any).mailContent : null;
      editor.commands.setContent(reportDate ? reportTemplate : '');
      setEditorContent(reportTemplate ?? editor.getHTML());
    }
  }, [reportDate, state.report]);
  const customEditor = () => (<>
  <div>
    <EditorMenuBar editor={editor as any} />
    <EditorContent editor={editor as any} className={styles.editor} />
      <BubbleMenu
        tippyOptions={{ duration: 150 }}
        editor={editor as any}
        shouldShow={({ from, to }) => {
          return from !== to;
        }}
      >
        <EditorMenuBar editor={editor as any} isPopup />
      </BubbleMenu>
    </div>
      </>);

  return (
    state.report ? <div className="admin-container">
      <ATMGrid columns={16}>
        <ATMGrid.Row>
          <ATMGrid.Column width={16}>
            {loading && (<Dimmer active inverted>
              <ATMLoader inverted content={Lang.LBL_LOADING} />
            </Dimmer>)}
            <TextEditor
              content=""
              extensions={extensions as any}
              editor={editor as any}
              editorContent={editorContent}
              children={customEditor()}
              setEditorContent={setEditorContent}
              isCustom
            />
            </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </div> :
    !reportDate ?
    (<ATMGrid columns={16}>
      <ATMGrid.Row>
        <ATMGrid.Column width={16}>
        {loading &&
        (<Dimmer active inverted>
          <ATMLoader inverted content={Lang.LBL_LOADING} />
        </Dimmer>)}
        <div className={styles.searchContainerDefault}>
          <ATMIcon
            circular
            name="search"
            size="large"
            className={styles.searchIcon}
          />
          <ATMHeader
            as="h3"
            content={Lang.LBL_VIEW_RECORDS}
            className={styles.title}
          />
          <p className={styles.subtitle}>{Lang.LBL_BPOR_SEARCH_ENTRY}</p>
        </div>
        </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>) :
      (<ATMGrid columns={16}>
        <ATMGrid.Row>
          <ATMGrid.Column width={16}>
          {loading &&
          (<Dimmer active inverted>
            <ATMLoader inverted content={Lang.LBL_LOADING} />
          </Dimmer>)}
          <div className={styles.searchContainer}>
          <TextEditor
            content=""
            extensions={extensions as any}
            editor={editor as any}
            editorContent={editorContent}
            children={customEditor()}
            setEditorContent={setEditorContent}
            isCustom
          />
          </div>
          </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>)
  );
};

export default RealTimeLogBPORReportView;
