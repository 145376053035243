import React, { useCallback, useState } from 'react';
import { ATMButton, ATMField, ATMModal } from 'shared-it-appmod-ui';
import DocumentGenerator from 'src/helpers/file-bpor-report.helper';
import Lang from 'src/libraries/language';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { ToastError, ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import styles from './real-time-log-bpor-report.module.scss';

type IProps = {
    editorContent: string;
  };

const RealTimeLogSendBPORReport: React.FC<IProps> = ({ editorContent }) => {
  const { state, actions } = useRealTimeLogContext();
  const [isOpen, setIsOpen] = useState(false);
  const status = getRealTimeLogStatus(
      state,
      realTimeLogActionTypes.REAL_TIME_LOG_MAILER
  );
  const handleBPORReport = useCallback(async () => {
    setIsOpen(false);
    const document = new DocumentGenerator(editorContent);
    const generatedDocument = await document.generateDocument();
    const result = await actions.handleBPORReportEmail({ base64String: [generatedDocument] });
    if (result?.payload) {
      if (result.payload?.status === 1) {
        ToastSuccess(result.payload?.message ?? 'Email successfully sent.');
      }
    }
    if (result?.error) {
      ToastError(result.error?.message);
    }
  }, [actions, editorContent, setIsOpen]);
    return (<>
        <ATMModal
          open={isOpen}
          size="tiny"
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          closeOnDimmerClick={false}
          trigger={<ATMField
            as={ATMButton}
            primary
            icon="send"
            content="Send"
            type="button"
            className={styles.inline}
            name="sendBporReport"
            loading={status.fetching}
          />}
        >
        <ATMModal.Header>Send BPOR Report</ATMModal.Header>
          <ATMModal.Content>
            <p>{Lang.MSG_SEND_BPOR_REPORT}</p>
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              secondary
              content={Lang.LBL_CANCEL}
              onClick={() => setIsOpen(false)}
              type="button"
            />
             <ATMField
               as={ATMButton}
               type="button"
               content="Send Report"
               primary
               icon="send"
               className={styles.inline}
               loading={status.fetching}
               name="sendReport"
               onClick={handleBPORReport}
             />
        </ATMModal.Actions>
    </ATMModal>
    </>
    );
};

export default RealTimeLogSendBPORReport;
