/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { AlignmentType, BorderStyle, Document, Footer, Header, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from 'docx';
import { BPOR_REPORT_FOOTER, BPOR_REPORT_FOOTER_CLASSIFICATION, BULK_POWER, ELEMENT_NODE, ElementAttribute, FONT_FAMILY, OPERATIONS_REPORT } from 'src/constants/file-bpor-report.constant';
// import { saveAs } from 'file-saver';
import { IRealTimeLogBPORTextRun } from 'src/models/real-time-log.model';
import { REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL, SYSTEM_CHANGES_FIELD_LABEL } from 'src/constants';

type IDocument = {
    content: string;
    fontSize: number;
};

export type ITextRun = {
  text: string;
  italics: boolean;
  bold: boolean;
  underline: any;
  font: string;
  size: number;
  color: string;
};
class DocumentGenerator {
    private document: IDocument;

    constructor(content) {
        this.document = {
            content,
            fontSize: 22,
        };
    }

    setContent(content: string) {
        this.document.content = content;
    }

    getContent() {
        return this.document.content;
    }

    toInches(inches: number) {
      return inches * 1440;
    }

    convert(percentage: number, totalWidth: number) {
      return (percentage / 100) * totalWidth;
    }

    getPageWidth() {
      const pageWidthInInches = 8.5;
      return this.toInches(pageWidthInInches);
    }

    getPageHeight() {
      const pageHeightInInches = 11;
      return this.toInches(pageHeightInInches);
    }

    getFontSize() {
      return this.document.fontSize;
    }

    convertWidthToInches(percent) {
      const pageWidthInInches = 8.5;
      const pageWidthInches = this.toInches(pageWidthInInches);
      return this.convert(percent, pageWidthInches);
    }

    convertHeightToInches(percent) {
      const pageHeightInInches = 11;
      const pageHeightInches = this.toInches(pageHeightInInches);
      return this.convert(percent, pageHeightInches);
    }

    getHeaderImage() {
      // readFileSync('C:/DevOps/working_branch/sdge-tdg-mcc/client/src/assets/images/sdge-logo.png');
      return '';
    }

    getElementAttribute(attribute: any): ElementAttribute {
      if (attribute?.length) {
        const attrs = attribute?.toString()?.split(':');
        if (attrs?.length) {
          if (attrs.length === 2) {
            switch (attrs[0]) {
              case 'text-align': {
                return {
                  Alignment: attrs[1]?.trim(), Color: ''
                };
              }
              case 'color': {
                return {
                  Alignment: '' as any, Color: attrs[1]?.trim()
                };
              }
              default: break;
            }
          }
        }
      }

      return { Alignment: AlignmentType.LEFT as any, Color: '#000000' };
    }

    convertRGBToHex(r: number, g: number, b: number): string {
      r = Math.max(0, Math.min(255, r));
      g = Math.max(0, Math.min(255, g));
      b = Math.max(0, Math.min(255, b));

      const toHex = (value: number): string => {
          const hex = value.toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  getIndent(child) {
    const indent = (child?.match(/&nbsp;/g) || [])?.length * 20;
    return indent;
  }

  applyNoBreakingSpace(fieldName) {
    const NBSP = '&nbsp;';

    const applySpaceLimit = (limit: number) => {
      let nbsp = fieldName;
      for (let i = 0; i < limit; i++) {
        nbsp += NBSP;
      }
      return nbsp;
    };
    if (fieldName.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL)) {
      return applySpaceLimit(2)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE)) {
      return applySpaceLimit(2)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED)) {
      return applySpaceLimit(1)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED)) {
      return applySpaceLimit(1)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED)) {
      return applySpaceLimit(1)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(SYSTEM_CHANGES_FIELD_LABEL.CHANGE)) {
      return applySpaceLimit(1)?.replace(/&nbsp;/g, ' ');
    }
    if (fieldName.includes(SYSTEM_CHANGES_FIELD_LABEL.LER)) {
      return applySpaceLimit(1)?.replace(/&nbsp;/g, ' ');
    }
    return fieldName;
  }

    getAllChildElements(element): any {
      const childNodes = Array.from(element);
      const isNodeElement = (node) => node.nodeType === Node.ELEMENT_NODE;
      const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
      const isRGB = (color) => color?.match(regex);
      const getChildNodes = (elem, attr: IRealTimeLogBPORTextRun): IRealTimeLogBPORTextRun => {
          switch (elem.nodeType) {
            case Node.ELEMENT_NODE: {
              switch (elem.tagName) {
                case ELEMENT_NODE.BOLD: {
                  attr.textRun.bold = true;
                  isNodeElement(elem) && elem.children.forEach((child) => {
                    getChildNodes(child, attr);
                });
                break;
                }
                case ELEMENT_NODE.ITALIC: {
                  attr.textRun.italics = true;
                  isNodeElement(elem) && elem.children.forEach((child) => {
                    getChildNodes(child, attr);
                });
                break;
                }
                case ELEMENT_NODE.UNDERLINE: {
                  attr.textRun.underline = { type: 'single' };
                  isNodeElement(elem) && elem.children.forEach((child) => {
                    getChildNodes(child, attr);
                });
                break;
                }
                case ELEMENT_NODE.TEXT: {
                  attr.textRun.text = elem.textContent;
                  isNodeElement(elem) && elem.children.forEach((child) => {
                    getChildNodes(child, attr);
                });
                break;
                }
                case ELEMENT_NODE.SPAN: {
                  // attr.textRun.text = elem.textContent;
                  attr.textRun.text = this.applyNoBreakingSpace(elem.textContent);
                  const indent = this.getIndent(elem.innerHTML);
                  const el = elem as Element;
                  const style = this.getElementAttribute(el.getAttribute('style'));
                  if (style) {
                    attr.alignment = style.Alignment as any;
                    const rgb = isRGB(style?.Color);
                    const color = rgb?.length > 3 ? this.convertRGBToHex(rgb[1], rgb[2], rgb[3]) : null;
                    attr.textRun.color = color || style?.Color as any;
                  }
                  if (indent && attr.indent) {
                    attr.indent.left = indent;
                  }
                  isNodeElement(elem) && elem.children.forEach((child) => {
                      getChildNodes(child, attr);
                  });
                break;
                }
                default: {
                  isNodeElement(elem) && elem.children.forEach((child) => {
                    getChildNodes(child, attr);
                });
                  break;
                }
              }
              break;
            }
            case Node.TEXT_NODE: {
              attr.textRun.text = elem.textContent;
              break;
            }
        }
        return attr;
      };
      const elements: any[] = [];
      const styles: any[] = [];
      childNodes.map((elem: any) => {
        const attributes: IRealTimeLogBPORTextRun = {
          textRun: {
            text: '',
            italics: false,
            bold: false,
            underline: null as any,
            font: FONT_FAMILY.TIMES_NEW_ROMAN,
            size: this.getFontSize(),
            color: '#000000',
          },
          alignment: AlignmentType.LEFT as any,
          indent: {
            left: 0,
            right: 0,
          },
        };
        getChildNodes(elem, attributes);
        elements.push(new TextRun(attributes.textRun as any));
        styles.push(attributes.indent);
        return null;
      });
      return {
        els: elements,
        atts: styles,
      } as {
        els: any[],
        atts: any[],
      };
  }

    public convertHTMLToDocument(html: string) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const paragraphs: Paragraph[] = [];
      const addText = (content, fontSize) => {
        return new TextRun({
          text: content,
          font: FONT_FAMILY.TIMES_NEW_ROMAN,
          size: fontSize || this.getFontSize(),
        });
      };
        Array.from(doc.body.children).forEach((children) => {
          switch (children.nodeName) {
            case ELEMENT_NODE.HEADING_LEVEL_1: {
              paragraphs.push(new Paragraph({
                text: children.textContent || '',
                heading: HeadingLevel.HEADING_1,
              }));
              break;
            }
            case ELEMENT_NODE.PARAGRAPH: {
              let textAlign = AlignmentType.LEFT;
              if (children.nodeType === Node.ELEMENT_NODE) {
                const attr = this.getElementAttribute(children.getAttribute('style'));
                textAlign = attr?.Alignment as any;
              }
              const childElements = this.getAllChildElements(children?.children);

              paragraphs.push(new Paragraph({
                children: [
                  ...childElements?.els,
                ],
                alignment: textAlign,
              }));
              break;
            }
            case ELEMENT_NODE.HORIZONTAL_RULE: {
              paragraphs.push(new Paragraph({
                children: [
                  addText('', null),
                ],
              }));
              break;
            }
            case ELEMENT_NODE.UNORDERED_LIST: {
              children.childNodes.forEach((val) => {
                val.childNodes.forEach((item) => {
                  paragraphs.push(new Paragraph({
                    children: [
                      addText(item.textContent || '', null),
                    ],
                    bullet: {
                      level: 0,
                    }
                  }));
                });
              });
              break;
            }
            case ELEMENT_NODE.ORDERED_LIST: {
              children.childNodes.forEach((val) => {
                val.childNodes.forEach((item) => {
                  paragraphs.push(new Paragraph({
                    children: [
                      addText(item.textContent || '', null),
                    ],
                    numbering: {
                      reference: 'numbering',
                      level: 0,
                    },
                    indent: {
                      left: 360,
                    }
                  }));
                });
              });
              break;
            }
            default:
              break;
        }
      });
      return paragraphs;
    }

    public createDocument(): Document {
      const document = new Document({
        numbering: {
          config: [
            {
              reference: 'numbering',
              levels: [
                {
                  level: 0,
                  format: 'decimal',
                  start: 1,
                  text: '%1.',
                  alignment: AlignmentType.LEFT,
                },
              ],
            },
          ],
        },
        sections: [
            {
                properties: {
                  page: {
                    size: {
                        width: this.getPageWidth(),
                        height: this.getPageHeight(),
                    },
                    margin: {
                        top: 720,
                        right: 720,
                        bottom: 720,
                        left: 720,
                    },
                },
                },
                headers: {
                  default: this.createHeader(),
                },
                children: this.convertHTMLToDocument(this.document.content),
                footers: {
                  default: this.createFooter(),
                },
            },
        ],
    });
    return document;
    }

    public createHeader(): Header {
      const styles = {
        borders: {
          top: { style: BorderStyle.NONE },
          bottom: { style: BorderStyle.NONE },
          left: { style: BorderStyle.NONE },
          right: { style: BorderStyle.NONE },
        },
        cellBorders: {
          top: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          bottom: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          left: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          right: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        },
        spacing: {
          before: 240,
          after: 240,
        },
      };
      const header = new Header({
        children: [
            new Table({
                borders: styles.borders,
                rows: [
                    new TableRow({
                        children: [
                            new TableCell({
                                width: {
                                  size: this.convertHeightToInches(30),
                                  type: WidthType.DXA,
                                },
                                borders: styles.cellBorders,
                                children: [
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                      new TextRun({
                                        bold: true,
                                        size: 24,
                                        font: FONT_FAMILY.ARIAL_BLACK,
                                        text: BULK_POWER,
                                      })
                                    ],
                                    spacing: styles.spacing,
                                    // indent: {
                                    //   left: 460,
                                    // }
                                  }),
                                ],
                            }),
                            new TableCell({
                              width: {
                                size: this.convertHeightToInches(40),
                                type: WidthType.DXA,
                              },
                              borders: styles.cellBorders,
                                children: [
                                    new Paragraph({
                                        border: styles.borders,
                                        children: [
                                            // new ImageRun({
                                            //     data: this.getHeaderImage(),
                                            //     transformation: {
                                            //         width: 140,
                                            //         height: 40,
                                            //     },
                                            // }),
                                            new TextRun({
                                              bold: false,
                                              size: 22,
                                              font: FONT_FAMILY.ARIAL,
                                              text: '',
                                            }),
                                        ],
                                        alignment: AlignmentType.CENTER,
                                        spacing: {
                                          before: 40,
                                          after: 40,
                                        },
                                    }),
                                ],
                            }),
                            new TableCell({
                              width: {
                                size: this.convertHeightToInches(30),
                                type: WidthType.DXA,
                              },
                              borders: styles.cellBorders,
                              children: [
                                new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                    new TextRun({
                                      bold: true,
                                      size: 24,
                                      font: FONT_FAMILY.ARIAL_BLACK,
                                      text: OPERATIONS_REPORT,
                                    })
                                  ],
                                  spacing: styles.spacing,
                                }),
                              ],
                            }),
                        ],
                    }),
                ],
            }),
        ],
      });
      return header;
    }

    public createFooter(): Footer {
      const styles = {
        borders: {
          top: { style: BorderStyle.NONE, size: 0 },
          bottom: { style: BorderStyle.NONE, size: 0 },
          left: { style: BorderStyle.NONE, size: 0 },
          right: { style: BorderStyle.NONE, size: 0 },
        },
        cellBorders: {
          top: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          bottom: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          left: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          right: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        },
        spacing: {
          before: 40,
          after: 40,
        },
      };
      const footer = new Footer({
        children: [
          // new Paragraph({
          //   spacing: {
          //     before: 40,
          //     after: 40,
          //   },
          //   alignment: AlignmentType.CENTER,
          //   children: [
          //     new TextRun({
          //       size: 22,
          //       font: FONT_FAMILY.ARIAL,
          //       text: BPOR_REPORT_FOOTER_CLASSIFICATION,
          //     })
          //   ],
          // }),

          // alternative footer
          new Table({
            borders: styles.borders,
            margins: {
              bottom: 30,
            },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                          width: {
                            size: this.convertHeightToInches(30),
                            type: WidthType.DXA,
                          },
                          borders: styles.cellBorders,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    bold: false,
                                    size: 22,
                                    font: FONT_FAMILY.ARIAL,
                                    text: '',
                                  })
                                ],
                                // spacing: styles.spacing,
                              }),
                            ],
                        }),
                        new TableCell({
                          width: {
                            size: this.convertHeightToInches(40),
                            type: WidthType.DXA,
                          },
                          borders: styles.cellBorders,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    bold: false,
                                    size: 22,
                                    font: FONT_FAMILY.ARIAL,
                                    text: BPOR_REPORT_FOOTER_CLASSIFICATION,
                                  })
                                ],
                                // spacing: styles.spacing,
                              }),
                            ],
                        }),
                        new TableCell({
                          width: {
                            size: this.convertHeightToInches(30),
                            type: WidthType.DXA,
                          },
                          borders: styles.cellBorders,
                            children: [
                              new Paragraph({
                                children: [
                                    new TextRun({
                                        // text: 'Page ',
                                        text: '',
                                        size: 22,
                                        font: FONT_FAMILY.ARIAL,
                                    }),
                                    // new TextRun({
                                    //     text: 'PAGE_NUM',
                                    //     size: 22,
                                    //     font: FONT_FAMILY.ARIAL,
                                    // }),
                                ],
                                alignment: AlignmentType.RIGHT,
                              }),
                            ],
                        }),
                    ],
                }),
            ],
        }),

          new Paragraph({
            alignment: AlignmentType.LEFT,
            spacing: {
              after: 240,
            },
            children: [
              new TextRun({
                italics: true,
                font: FONT_FAMILY.TAHOMA,
                size: 16,
                color: '#0000FF',
                text: BPOR_REPORT_FOOTER,
              }),
            ],
          }),
        ],
      });
      return footer;
    }

    public async generateDocument() {
      const doc = this.createDocument();
      const base64String = await Packer.toBase64String(doc);
      // const blob = await Packer.toBlob(doc);
      // saveAs(blob, 'BPOR_REPORT.docx');
      return base64String;
    }
}

export default DocumentGenerator;
